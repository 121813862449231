import React, { Component } from "react";
import {lazyWithRetry } from "./ComponentLoader";

const OrganizationPage = lazyWithRetry(() => import("../../../../pages/Organization"));


const AssetDetailPage = lazyWithRetry(() => import("../../../../pages/AssetDetail"));
const AssetsPage = lazyWithRetry(() => import("../../../../pages/Assets"));
const AssetDescriptionPage = lazyWithRetry(
    () => import("../../../../pages/AssetsDescription")
);
const AssetTableViewPage = lazyWithRetry(() => import("../../../../pages/AssetsTable"));


const ListedAssetsDescriptionPage = lazyWithRetry(
    () => import("../../../../pages/ListedAssetsDescription")
);

const ClaimDescriptionPage = lazyWithRetry(
    () => import("../../../../pages/ClaimAssetsDescription")
);

const ClaimAssetTablePage = lazyWithRetry(
    () => import("../../../../pages/ClaimAssetTable")
);


const ListedAssetsPage = lazyWithRetry(() => import("../../../../pages/ListedAssets"));
const ListedAssetDetailPage = lazyWithRetry(
    () => import("../../../../pages/ListedAssetDetail")
);
const ListedAssetTablePage = lazyWithRetry(
    () => import("../../../../pages/ListedAssetsTable")
);

const ListedAssetTableStorePage = lazyWithRetry(
    () => import("../../../../pages/ListedStoreListTable")
);

const ClaimTableStorePage = lazyWithRetry(
    () => import("../../../../pages/ClaimTableStoreList")
);

const SoldAssetDescriptionPage = lazyWithRetry(
    () => import("../../../../pages/SoldAssetDescription")
);
const SoldAssetsPage = lazyWithRetry(() => import("../../../../pages/SoldAssets"));
const SoldAssetDetailPage = lazyWithRetry(
    () => import("../../../../pages/SoldAssetDetail")
);
const SoldAssetTablePage = lazyWithRetry(
    () => import("../../../../pages/SoldAssetsTable")
);

const SoldAssetTableStorePage = lazyWithRetry(
    () => import("../../../../pages/SoldTableStoreList")
);

const CreateCollectablePage = lazyWithRetry(
    () => import("../../../../pages/CreateCollectables")
);

const DashboardPage = lazyWithRetry(
    ()=> import ("../../../../pages/Dashboard")
);

const OwnedAssetsDescriptionPage = lazyWithRetry(
    () => import("../../../../pages/OwnedAssetsDescription")
);

const OwnedAssetTableView =  lazyWithRetry(
    () => import("../../../../pages/OwnedAssetTable")
);
const OwnedAssetsDetailPage = lazyWithRetry( () => import("../../../../pages/OwnedAssetDetail"))

const OwnedAssetsPage = lazyWithRetry( () => import("../../../../pages/OwnedAssets"))


const CreateAssetDescriptionPage = lazyWithRetry(()=>import("../../../../pages/CreateAssetDescription"));

const StoresPage = lazyWithRetry(()=>import("../../../../pages/StoresList"));
const ProfileScreenPage = lazyWithRetry(()=>import("../../../../pages/ProfileScreen"));
const WalletScreenPage = lazyWithRetry(()=>import("../../../../pages/WalletScreen"));

const ClaimAssets = lazyWithRetry(()=>import("../../../../pages/ClaimAssets"));

// const MyAssetsPage = lazyWithRetry(() => import("../../../../pages/MyAssets"));
// const MyAssetDetailPage = lazyWithRetry(() => import("../../../../pages/MyAssetDetail"));

// const MyWalletPage = lazyWithRetry(() => import("../../../../pages/MyWallet"));
const AnalyticsPage = lazyWithRetry(() => import("../../../../pages/Analytics"));

const SoldStorePage = lazyWithRetry(() => import("../../../../pages/SoldAssetStoreList"));
const ClaimStorePage = lazyWithRetry(() => import("../../../../pages/ClaimAssetStoreList"));
const ClaimAssetDetailPage = lazyWithRetry(() => import("../../../../pages/ClaimAssetDetail"));
const usersTablePage = lazyWithRetry(()=>import("../../../../pages/MinterOnboard"));
const OnboardMinterPage = lazyWithRetry(()=>import("../../../../pages/OnboardMinter")); 
const ShowcasedAssetsDescriptionPage = lazyWithRetry(()=>import("../../../../pages/ShowcasedAssetsDescription"));
const ShowcasedAssetsPage = lazyWithRetry(()=>import("../../../../pages/ShowcasedAssets"));
const ShowcasedAssetDetailPage =lazyWithRetry(()=>import("../../../../pages/ShowcasedAssetDetail"));
const ShowcasedStoresPage = lazyWithRetry(()=>import("../../../../pages/ShowcasedAssetStoreList"));
export const routes = [
    { path: "/organization", Component: OrganizationPage },
    { path: "/profile", Component: ProfileScreenPage },
    { path: "/asset-creators", Component: usersTablePage},
    { path: "/onboard-new-creator", Component:OnboardMinterPage},
    { path: "/wallet", Component: WalletScreenPage },
    { path: "/asset-description", Component: AssetDescriptionPage },
    { path: "/assets-list", Component: AssetsPage },
    { path: "/table-view", Component: AssetTableViewPage },
    { path: "/assets-list/asset", Component: AssetDetailPage },
    { path: "/listed-asset-description", Component: ListedAssetsDescriptionPage },
    { path: "/listed-assets/store", Component: ListedAssetsPage },
    { path: "/listed-assets", Component: StoresPage },
    { path: "/listed-assets/asset", Component: ListedAssetDetailPage },
    { path: "/listed-assets-table", Component: ListedAssetTableStorePage },
    { path: "/listed-assets-table/store", Component: ListedAssetTablePage },
    { path: "/listed-assets-table/asset", Component: ListedAssetDetailPage },
    { path: "/sold-asset-description", Component: SoldAssetDescriptionPage },
    { path: "/sold-assets/store", Component: SoldAssetsPage },
    { path: "/sold-assets", Component: SoldStorePage },
    { path: "/sold-assets/asset", Component: SoldAssetDetailPage },
    { path: "/sold-assets-table", Component: SoldAssetTableStorePage },
    { path: "/sold-assets-table/store", Component: SoldAssetTablePage },
    { path: "/sold-assets-table/asset", Component:  SoldAssetDetailPage},
    { path: "/create-assets", Component: CreateCollectablePage },
    { path: "/table-view/asset", Component: AssetDetailPage },
    { path: "/analytics", Component: AnalyticsPage },
    { path: "/create-asset-description", Component: CreateAssetDescriptionPage },
    { path:"/stores", Component:StoresPage },
    { path:"/claim-asset-description", Component:ClaimDescriptionPage },
    { path:"/claim-assets", Component:ClaimStorePage },
    { path:"/claim-assets-table/store", Component:ClaimAssetTablePage },
    { path:"/claim-assets-table", Component:ClaimTableStorePage },
    { path:"/claim-assets/store", Component:ClaimAssets },
    { path:"/claim-assets/asset", Component:ClaimAssetDetailPage },
    { path:"/claim-assets-table/asset", Component:ClaimAssetDetailPage },
    { path:"/owned-assets-table", Component:OwnedAssetTableView },
    { path:"/owned-assets-description", Component:OwnedAssetsDescriptionPage },
    { path:"/owned-asset/asset", Component:OwnedAssetsDetailPage },
    { path:"/owned-assets", Component:OwnedAssetsPage },
    {path:"/showcased-assets-description", Component:ShowcasedAssetsDescriptionPage},
    {path:"/showcased-assets", Component: ShowcasedStoresPage},
    {path:"/showcased-assets/store", Component: ShowcasedAssetsPage},    
    {path:"/showcased-asset/asset", Component: ShowcasedAssetDetailPage},
    {path:"/", Component: DashboardPage},
  
    // { path: "/", Component: AssetsPage },
    
    
    // { path: "/my-assets", Component: MyAssetsPage },
    // { path: "/my-assets/asset", Component: MyAssetDetailPage },
    // { path: "/my-wallet", Component: MyWalletPage },
    
];
