//@ts-nocheck
import React, { Fragment, useEffect, useState } from "react";
import Loader from "./component/common/loader/loader";
import Header from "./component/common/header/header";
import HeaderSM from "./component/common/header/headerSM";
import Sidebar from "./component/common/sidebar/sidebar";
import SidebarSM from "./component/common/sidebar/sidebarsm";
import Rightsidebar from "./component/common/sidebar/rightsidebar";
import Footer from "./component/common/footer/footer";
import { useGetNavitem } from "./appProvider";
import {useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { AuthService } from "../../../services";
// import { useAuth } from "../../../services/auth-service/auth.provider";
import { VApplication } from "../../../application";


import {
    useViewport,
    ViewportProvider,
} from "@vlinder-web/viewport-provider-react";

const loginService = VApplication.getInstance().getSync(
    "services.AuthService"
) as AuthService;


const _MENU_ITEMS = [
    {
        eventKey: "organization",
        title: "Profile",
        icon: <i class="icon-home"></i>,
        path: "/profile",
        type: "sub",
        active: false,
        bookmark: false,
        children: [{ title: "Account", type: "sub" },
        {
            title: "Profile",
            type: "link",
            path: `/profile`,
        },
        {
            title: "Wallet",
            type: "link",
            path: `/wallet`,
        }
                ],
    },
    {
        eventKey: "analytics",
        title: "Analytics",
        icon: <i className="icon-bar-chart-alt"></i>,
        path: "/analytics",
        type: "sub",
        active: false,
        children: [{ title: "Analytics", type: "sub" }],
    },
    {
        eventKey: "collectibleForm",
        title: "Create",
        icon:  <i className= "icon-pencil-alt"></i>,
        path: "/create-asset-description",
        type: "sub",
        active: false,
        children: [
            { title: "Assets", type: "sub" },
            {
                title: "Register",
                type: "link",
                path: `/create-assets`,
            },
        ],
    },
    {
        eventKey: "createdAsset",
        title: "Created",
        icon:<i className="pe-7s-diamond"></i> ,
        path: "/asset-description",
        type: "sub",
        active: false,
        children: [
            { title: "My Works", type: "sub" },
            {
                title: "My Works - Grid View",
                type: "link",
                path: `/assets-list`,
            },
            {
                title: "My Works - Table View",
                type: "link",
                path: `/table-view`,
            },
        ],
    },
    {
        eventKey: "ownedAssets",
        title: "Collaboration",
        icon: <i className="pe-7s-shopbag"></i>,
        path: "/owned-assets-description",
        type: "sub",
        active: false,
        children: [
            { title: "Collab Work", type: "sub" },
            {
                title: "Collab Work - Grid View",
                type: "link",
                path: `/owned-assets`,
            },
            {
                title: "Showcased Work  - Grid View",
                type: "link",
                path: `/showcased-assets`,
            },
            {
                title: "Collab Work - Table View",
                type: "link",
                path: `/owned-assets-table`,
            },
           
        ],
    },
    // {
    //     eventKey: "showcased",
    //     title: "Showcased",
    //     icon: <i className="pe-7s-shopbag"></i>,
    //     path: "/showcased-assets-description",
    //     type: "sub",
    //     active: false,
    //     children: [
    //         { title: "Showcased Assets", type: "sub" },
    //         {
    //             title: "Showcased Assets",
    //             type: "link",
    //             path: `/showcased-assets`,
    //         },
    //         // {
    //         //     title: "Showcased Assets Table",
    //         //     type: "link",
    //         //     path: `/showcased-assets-table`,
    //         // },
    //     ],
    // },
    // {
    //     eventKey: "Minted Assets",
    //     title: "",
    //     icon: <i className="icofont icofont-connection"></i>,
    //     path: "/mint-asset-description",
    //     type: "sub",
    //     active: false,
    //     children: [
    //         { title: "Minted Assets", type: "sub" },
    //         {
    //             title: "Minted Assets",
    //             type: "link",
    //             path: `/mint-assets`,
    //         },
    //         {
    //             title: "Minted Assets Table",
    //             type: "link",
    //             path: `/mint-assets-table`,
    //         },
    //     ],
    // },
    {
        eventKey: "Published Work for Sale",
        title: "Published",
        icon: <i className= "icon-list"></i>,
        path: "/listed-asset-description",
        type: "sub",
        active: false,
        children: [
            { title: "Published Work for Sale", type: "sub" },
            {
                title: "Published Work - Grid View",
                type: "link",
                path: `/listed-assets`,
            },
            {
                title: "Published Work - Table View",
                type: "link",
                path: `/listed-assets-table`,
            },
        ],
    },
    {
        eventKey: "DisplayedForClaim",
        title: "Shared",
        icon: <i className= "pe-7s-browser"></i>,
        path: "/claim-asset-description",
        type: "sub",
        active: false,
        children: [
            { title: "Assets Displayed for Share", type: "sub" },
            {
                title: "Shared Work - Grid View",
                type: "link",
                path: `/claim-assets`,
            },
            {
                title: "Shared Work - Table View",
                type: "link",
                path: `/claim-assets-table`,
            },
        ],
    },
    {
        eventKey: "Sold Assets",
        title: "Amended Works",
        icon: <i className= "icon-bag"></i>,
        path: "/sold-asset-description",
        type: "sub",
        active: false,
        children: [
            { title: "Amended Works", type: "sub" },
            {
                title: "Amended Works - Grid View",
                type: "link",
                path: `/sold-assets`,
            },
            {
                title: "Amended Works - Table View",
                type: "link",
                path: `/sold-assets-table`,
            },
        ],
    },
];

const _getChildren = (childItems:any) =>{
    const _children = childItems?.map(item=>{
        if(item?.title!=="Wallet"){
            return({
                ...item
            })
        }
    })
    return _children;
}

const _getMenuItems =(userType:string, userRole?:string) =>{
    console.log('inside application user :', userRole === "platform owner");
    if((userRole !== "broker" && userRole !=="platform owner") && (userType=== "individual" || userType=== "organization")){
        return _MENU_ITEMS;
    }
    else if(userRole=== "user"){
        return _MENU_ITEMS?.filter(item=>item?.eventKey !== "createdAsset");
    }
    else if(userRole === "broker" || userRole === "platform owner"){
        const _menuItems = [];
        _menuItems.push(
            {            
                eventKey: "organization",
                title: "Profile",
                icon: <i class="icon-home"></i>,
                path: "/profile",
                type: "sub",
                active: false,
                bookmark: false,
                children: [{ title: "Account", type: "sub" },
                {
                    title: "Profile",
                    type: "link",
                    path: `/profile`,
                },
                {
                    title: "Wallet",
                    type: "link",
                    path: `/wallet`,
                }
                        ],
            },
            {
                eventKey: "users",
                title: "Asset creators",
                icon: <i class="pe-7s-users"></i>,
                path: "/asset-creators",
                type: "sub",
                active: false,
                bookmark: false,
                children: [{ title: "Asset creators", type: "sub" },
                {
                    title: "Creators List",
                    type: "link",
                    path: `/asset-creators`,
                },
                {
                    title: "Onboard Creator",
                    type: "link",
                    path:"/onboard-new-creator"
                }
                ], 
            }
        )
        return _menuItems;
    }
    // else if(userRole === "platform owner"){
    //     const _menuItems = [];
    //     _menuItems.push(
    //         {            
    //             eventKey: "organization",
    //             title: "Profile",
    //             icon: <i class="icon-home"></i>,
    //             path: "/profile",
    //             type: "sub",
    //             active: false,
    //             bookmark: false,
    //             children: [{ title: "Account", type: "sub" },
    //             {
    //                 title: "Profile",
    //                 type: "link",
    //                 path: `/profile`,
    //             },
    //             {
    //                 title: "Wallet",
    //                 type: "link",
    //                 path: `/wallet`,
    //             }
    //                     ],
    //         },

    //     )
    //     return _menuItems;
    // }
}

const getAuthSerivice = () => {
    const vapp = VApplication.getInstance();
    const [userName, setUserName] = useState<string>();
    const authSerivice = vapp.getSync(
        "services.AuthService"
    ) as AuthService;
    return authSerivice;
};

const App = ({ children, ...props }) => {
    const MENU_ITEMS = _getMenuItems(props?.userType, props?.userRole);
    // const MENU_ITEMS = _MENU_ITEMS;
    const { bp, width } = useViewport();
    const [userName, setUserName] = useState<string>();
    // const {getUserProfile, signOut} = useAuth();
    const history = useHistory();
    useEffect(()=>{
        loginService.getUserProfile().then(res=>{
            console.log(`user profile in bugsbunny`, res);
            setUserName(res?.fullName);
        })
    },[])

    const onLogoutClick =() => {
    
        console.log('logout button pressed');
        loginService.signOut();
        window.open('/', "_self");
        // window.location.reload();
        
    }

    // let _sideBarItems = [];
    // Array.isArray(MENU_ITEMS) &&
    //   MENU_ITEMS.map((item) => {
    //     let _tempNav;
    //     context?.payload?.navItems?.map((ele) => {
    //       if (item?.eventKey === ele?.key && ele?.enable) {
    //         _sideBarItems.push({
    //           ...item,
    //         });
    //       }
    //     });
    //   });

    const getSidebar = () => {
        if (bp === "xs" || bp === "sm") {
            return <SidebarSM menuItems={MENU_ITEMS} />;
        } else {
            return <Sidebar menuItems={MENU_ITEMS} />;
        }
    };

    const getHeader = () => {
        if (bp === "xs" || bp == "sm") {
            return <HeaderSM menuItems={MENU_ITEMS} searchbar={false} userName={userName} onLogoutClick={onLogoutClick}/>;
        } else {
            return <Header menuItems={MENU_ITEMS} searchbar={false} userName={userName} onLogoutClick={onLogoutClick}/>;
        }
    };
    return (
        <Fragment>
            <Loader />
            <div className="page-wrapper">
                <div className="page-body-wrapper">
                    {getHeader()}
                    {getSidebar()}
                    <Rightsidebar />
                    <div className="page-body">{children}</div>
                    <Footer />
                    {/* <ThemeCustomize/> */}
                </div>
            </div>
            <ToastContainer
                autoClose={2000}
                position="top-center"
                className="toast-container"
                toastClassName="dark-toast"
            />
        </Fragment>
    );
};

export default App;
