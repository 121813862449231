export const STORES_DATA = [
    {
        key: 'vlimderStore0',
        _id:"61739cbd2fbe0a187c984fb6",
        title: 'Vlinder Store 1',
        subtitle1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        tooltip: 'Vlinder Store 1',
        subtile2: '',
        icon: {
          imageURI: 'https://i.ibb.co/v3md5bH/retail.png',
          imgWidth: 40,
          imgHeight: 40,
        },
      },
    {
      key: 'vlimderStore1',
      _id:"2",
      title: 'Vlinder Store 2',
      subtitle1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      tooltip: 'Vlinder Store 2',
      subtile2: '',
      icon: {
        imageURI: 'https://i.ibb.co/v3md5bH/retail.png',
        imgWidth: 40,
        imgHeight: 40,
      },
    },
    {
        key: 'vlimderStore3',
        _id:"3",
        title: 'Vlinder Store 3',
        subtitle1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        tooltip: 'Vlinder Store 3',
        subtile2: '',
        icon: {
          imageURI: 'https://i.ibb.co/v3md5bH/retail.png',
          imgWidth: 40,
          imgHeight: 40,
        },
      },
]